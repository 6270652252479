import { Injectable } from '@angular/core';

// Store
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.reducer';
import * as storeActions from '../store/actions';


@Injectable({
  providedIn: 'root'
})
export class PersistenceService {

  constructor(private _store: Store<AppState>) { }

  setSession(key: string, data: any) {
    try {
      sessionStorage.setItem(key, data);
    } catch (e) {
      console.log('Error en storage: ', e);
    }
  }

  getSession(key: string) {

    try {
      return sessionStorage.getItem(key);
    } catch (e) {
      console.log('Error en storage: ', e);
      return;
    }
  }

  setCurrentUser(currentUser: any) {
    this.removeCurrentUser();
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  getCurrentUser(): any{
    let currentUserResponse:any;
    let currentUser = sessionStorage.getItem('currentUser');
    if (currentUser!= null) {
      currentUserResponse = JSON.parse(currentUser);
    }
    return currentUserResponse;
  }

  removeCurrentUser() {
    sessionStorage.removeItem('currentUser');
  }

  setToken(token: any) {
    this.removeToken();
    sessionStorage.setItem('accessToken', JSON.stringify(token));
  }

  getToken(): any{
    let tokenResponse:any;
    let token = sessionStorage.getItem('accessToken');
    if (token!= null) {
      tokenResponse = JSON.parse(token);
    }
    return tokenResponse;
  }

  removeToken() {
    sessionStorage.removeItem('accessToken');
  }

  setCurrentPharmacy(currentPharmacy: any) {
    this.removeCurrentPharmacy();
    sessionStorage.setItem('currentPharmacy', JSON.stringify(currentPharmacy));
  }

  getCurrentPharmacy(): any{
    let currentPharmacyResponse:any;
    let currentPharmacy = sessionStorage.getItem('currentPharmacy');
    if (currentPharmacy!= null) {
      currentPharmacyResponse = JSON.parse(currentPharmacy);
    }
    return currentPharmacyResponse;
  }

  removeCurrentPharmacy() {
    sessionStorage.removeItem('currentPharmacy');
  }

  setPharmacies(pharmacies: any) {
    this.removePharmacies();
    sessionStorage.setItem('pharmacies', JSON.stringify(pharmacies));
  }

  getPharmacies(): any{
    let pharmaciesResponse:any;
    let pharmacies = sessionStorage.getItem('pharmacies');
    if (pharmacies!= null) {
      pharmaciesResponse = JSON.parse(pharmacies);
    }
    return pharmaciesResponse;
  }

  removePharmacies() {
    sessionStorage.removeItem('pharmacies');
  }

  //hidratate store (call app.component)
  hidratateStore(){

    //currentUser data persist (RESTAURANT-PROVIDER)
    if(this.getSession('currentUser')){
      this._store.dispatch( new storeActions.SetUserAction(
          this.getCurrentUser()
      ));
    }

    //currentUser data persist (RESTAURANT-PROVIDER)
    if(this.getSession('accessToken')){
      this._store.dispatch(new storeActions.SetTokenAction(
        this.getToken()
      ))
    }

    //currentUser data persist (RESTAURANT-PROVIDER)
    if(this.getSession('currentPharmacy')){
      this._store.dispatch(new storeActions.SetPharmacyAction(
        this.getCurrentPharmacy()
      ))
    }

    //currentUser data persist (RESTAURANT-PROVIDER)
    if(this.getSession('pharmacies')){
        this._store.dispatch(new storeActions.SetPharmaciesAction(
          this.getPharmacies()
        ))
      }

  }

  //clean session storage
  remove_sesionStorage(){
    this.removeCurrentUser();
    this.removeToken();
    this.removeCurrentPharmacy();
    this.removePharmacies();
  }
}
