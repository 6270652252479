import { Action } from "@ngrx/store";
import { Order } from "./../../interfaces/order-interfaces";

export const SET_ORDER_LIST = '[Order] Set order';
export const UNSET_ORDER_LIST = '[Order] Unset order';
export const SET_RESPONSE_LENGTH = '[Order] Set orders array length';
export const UNSET_RESPONSE_LENGTH = '[Order] Unset orders array length';
export const SET_CURRENT_ORDER = '[Order] Set Token';
export const UNSET_CURRENT_ORDER = '[Order] Unset Token';
export const UNSET_ALL_ORDERS = '[Order] Unset All Auth Data';


export class SetOrderListAction implements Action {
    readonly type=SET_ORDER_LIST;
    constructor( public orderList: Order[] | []){
    }
}

export class UnsetOrderListAction implements Action {
    readonly type = UNSET_ORDER_LIST;
}

export class SetResponseLengthAction implements Action {
    readonly type=SET_RESPONSE_LENGTH;
    constructor( public length: any){
    }
}

export class UnsetResponseLengthAction implements Action {
    readonly type = UNSET_RESPONSE_LENGTH;
}

export class SetCurrentOrderAction implements Action {
    readonly type=SET_CURRENT_ORDER;
    constructor( public order: Order){
    }
}

export class UnsetCurrentOrderAction implements Action {
    readonly type=UNSET_CURRENT_ORDER;
    constructor(){
    }
}

export class UnsetAllOrdersAction implements Action {
    readonly type = UNSET_ALL_ORDERS;
}

export type orderActions =  SetOrderListAction          |
                            UnsetOrderListAction        |
                            SetResponseLengthAction     |
                            UnsetResponseLengthAction   |
                            SetCurrentOrderAction       |
                            UnsetCurrentOrderAction     |
                            UnsetAllOrdersAction;
