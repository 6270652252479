import { environment } from 'environments/environment';

import { Injectable } from "@angular/core";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, Messaging, onMessage } from "firebase/messaging";

// Go to brave://settings/privacy or chrome://settings/privacy and ensure “Use Google services for push messaging” is enabled.

@Injectable({
    providedIn: 'root'
})
export class FirebaseFCMService {
    private readonly messaging: Messaging;
    private vapidKey = "BFLfCHdZ_ZoQmkxXkVJXpXV4cj85f0ruQ89Z6zAbth-u4GCac8NfRp_xcP0XhNUttgvPLEfKHbCedTinB5GvKYY";
    constructor(
    ) {
        const app = initializeApp(environment.fbConfig);
        this.messaging = getMessaging(app);
        this.setupMessageListener();
    }


    async requestPermission(): Promise<string | null> {
      try {
        // Solicitar permiso
        const permission = await Notification.requestPermission();
        
        if (permission === 'granted') {
          // Registrar service worker
          const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
          
          // Obtener token
          const token = await getToken(this.messaging, {
            vapidKey: this.vapidKey,
            serviceWorkerRegistration: registration
          });
    
          if (token) {
            return token;
          } else {
            return null;
          }
        } else {
          console.error('Permiso de notificaciones denegado');
          return null;
        }
      } catch (error) {
        console.error('Error al obtener el token:', error);
        return null;
      }
    }
    
    private setupMessageListener(): void {
      try {
        onMessage(this.messaging, {
          next: (payload) => {

            if (Notification.permission === 'granted') {

              const notificationTitle = payload.notification?.title || 'Nuevo mensaje';
              const notificationOptions = {
                body: payload.notification?.body || 'Tienes una nueva notificación',
                icon: payload.notification?.icon || '/favicon-32x32.png'
              };
              new Notification(notificationTitle, notificationOptions);
            }
          },
          error: (error) => console.log('Message error', error),
          complete: () => console.log('Done listening to messages'),
        });
      } catch (error) {
        console.error('Error al configurar listener:', error);
      }
    }
}