import { Action } from "@ngrx/store";

export const SET_MAP_COORDINATES = '[Map Data] Set map coordinates';
export const UNSET_MAP_COORDINATES = '[Map Data] Unset map coordinates';

export const SET_ADDRESS_CHOSEN = '[Map Data] Set address chosen';
export const UNSET_ADDRESS_CHOSEN = '[Map Data] Unset address chosen';

export class SetMapCoordinatesAction implements Action {
    readonly type=SET_MAP_COORDINATES;
    constructor( public payload:any){
    }
}

export class UnsetMapCoordinatesAction implements Action {
    readonly type=UNSET_MAP_COORDINATES;
}

export class SetAddressAction implements Action {
    readonly type=SET_ADDRESS_CHOSEN;
    constructor( public payload:any){
    }
}

export class UnsetAddressAction implements Action {
    readonly type=UNSET_ADDRESS_CHOSEN;
}

export type mapActions  =   SetMapCoordinatesAction     |
                            UnsetMapCoordinatesAction   |
                            SetAddressAction            |
                            UnsetAddressAction

